import React, { Component } from 'react'
import { slide as Menu } from 'react-burger-menu'

import {Link} from 'react-router-dom'

import './burgerMenu.css'

export default class MobileMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }

  showSettings (event) {
    event.preventDefault();
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu () {
    this.setState({menuOpen: false})
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu 
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
        className=''
      >
        <Link onClick={() => this.closeMenu()} to='/' id="home" className="menu-item" href="/">Главная</Link>
        <Link onClick={() => this.closeMenu()} to='/about' id="about" className="menu-item">О Компании</Link>
        <Link onClick={() => this.closeMenu()} to='/services' className="menu-item--small">Услуги</Link>
        <Link onClick={() => this.closeMenu()} to='/projects' className="menu-item--small">Проекты</Link>
        <Link onClick={() => this.closeMenu()} to='/contacts' id="contact" className="menu-item">Контакты</Link>
      </Menu>
    );
  }
}