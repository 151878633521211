
export default function FooterTwo(){
  return(
    <div className="footer fw-proBold">
      <div class="subheader box-shadow-2 footer-mt">
      </div>
      <div className="footer_inner text-center color-dark-blue fw-proBold">
        <span>©</span> 2008-2024 ТОО ПСК "ДизайнСтрой"
      </div>
    </div>
  )
}