import React from "react";
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
    sizes: '10px 10px'
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
    sizes: '10px 10px'
  },
];

export default class Gallery extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}