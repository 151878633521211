import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Header from "./Header";
import About from "./About";
import Services from "./Services";
import Projects from "./Projects";
import ProjectsTwo from "./ProjectsTwo";
import Contacts from "./Contacts";
import Home from './Home'
import ServicesLink from "./ServicesLink";
import ProjectsInfo from "./ProjectsInfo";
import MobileMenu from "./MobileMenu";
import Gallery from "./Gallery";

export default function App() {
  return (
    <Router>
        <Header page={'about'}  />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='/projects' element={<Projects/>} />
          <Route path='/projects-second' element={<ProjectsTwo/>} />
          <Route path='/contacts' element={<Contacts/>} />
          <Route path='/services-link' element={<ServicesLink/>}></Route>
          <Route path='/menu' element={<MobileMenu/>}></Route>

          <Route path='/projects/kaskad' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/kazpost' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/sayran' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/czech' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/gazprom' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/shahristan' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/dostyk' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/miras' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/navoy' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/legend' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/zhana' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/bank' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/bus' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/asyltobe' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/theatre' element={<ProjectsInfo/>}></Route>
          <Route path='/projects/sportcomplex' element={<ProjectsInfo/>}></Route>

          <Route path='/gallery' element={<Gallery/>}></Route>

        </Routes>
    </Router>
  );
}

