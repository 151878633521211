const projectInfoArray = [
  {
    number: '1',
    id: 'kaskad',
    name: 'Каскад ГЭС',
    mainImg: '/img/project/1/main.jpg',
    leftLink: 'sportcomplex',
    rightLink: 'sayran',
    text: 'Реконструкция и модернизация Каскада ГЭС.',
    textTwo: 'Подготовка ТЭО для Реконструкции и модернизации Каскада ГЭС (ГЭС-1, ГЭС-2), г. Алматы.',
    date: 'Май 2010г. - Июль 2010г.',
    images: [
      {
        original: '/img/project/1/main.jpg',
        // thumbnail: '/img/project/1/main.png'
      },
      {
        original: '/img/project/1/1.jpg',
        // thumbnail: '/img/project/1/1.png'
      },
      {
        original: '/img/project/1/2.jpg',
        // thumbnail: '/img/project/1/2.png'
      }
    ]
  },
  {
    number: '2',
    id: 'kazpost',
    name: 'АО "Казпочта"',
    mainImg: '/img/project/2/main.png',
    leftLink: 'kaskad',
    rightLink: 'sayran',
    text: 'Реконструкция  помещений  для отделения почтовой связи № 5 АО "Казпочта".  Многоквартирный  жилой  комплекс "Сайран", квартал 29, дом 148. Расположенный в квадрате улиц: Карасай Батыра, Брусиловского, Кулымбетова и Айвазовского в г. Алматы.',
    textTwo: 'Проектирование  и  строительство   под   ключ  в  чистовой отделке. Разработка проекта реконструкции, согласование, отделочные работы, монтаж инженерных сетей и лифтового оборудования.',
    date: 'Июнь - Август 2010г.',
    images: [
      {
        original: '/img/project/2/main.png',
        // thumbnail: '/img/project/1/main.png'
      },
      {
        original: '/img/project/2/1.png',
        // thumbnail: '/img/project/1/1.png'
      },
      {
        original: '/img/project/2/2.png',
        // thumbnail: '/img/project/1/2.png'
      }
    ]
  },
  {
    number: '3',
    id: 'sayran',
    name: 'МЖК "Сайран"',
    mainImg: '/img/project/3/main.png',
    leftLink: 'kazpost',
    rightLink: 'czech',
    text: 'Многоквартирный жилой комплекс "Сайран - 20 квартал", с объектами обслуживания и подземными автостоянками г. Алматы, в квадрате улиц: Карасай Батыра, Брусиловского, Есенжанова, Прокофьева.',
    textTwo: 'Строительство под ключ в черновой отделке. Разработка котлована, монтаж временного энергообеспечения, поставка материалов и оборудования, работы по устройству монолитного железобетонного фундамента с гидроизоляцией, устройство монолитного железобетонного каркаса, проведение технических испытаний, сдача выполненных работ уполномоченным государственным органам.',
    date: 'Июнь 2010г. - Декабрь 2011г.',
    images: [
      {
        original: '/img/project/3/main.png',
        // thumbnail: '/img/project/2/main.png'
      },
      {
        original: '/img/project/3/2.png',
        // thumbnail: '/img/project/2/2.png'
      }
    ]
  },
  {
    number: '4',
    id: 'czech',
    name: 'МЖК "Чешские Терассы"',
    mainImg: '/img/project/4/main.png',
    leftLink: 'sayran',
    rightLink: 'gazprom',
    text: 'Многоквартирный жилой комплекс ”Чешские терассы” , в г.Алматы, Медеуский район, мкр. Коктобе, ул. Маметова д. 64.',
    textTwo: 'Строительство под ключ в черновой отделке. Разработка котлована, устройство монолитного железобетонного фундамента, устройство свайной желебетонной плиты, устройство металлического каркаса, отделочные работы сдача выполненных работ уполномоченным органам.',
    date: 'Июнь 2011г. - Декабрь 2012г.',
    images: [
      {
        original: '/img/project/4/main.png',
        // thumbnail: '/img/project/3/main.png'
      },
      {
        original: '/img/project/4/1.png',
        // thumbnail: '/img/project/3/1.png'
      },
      {
        original: '/img/project/4/2.png',
        // thumbnail: '/img/project/3/2.png'
      }
    ]
  },
  {
    number: '5',
    id: 'gazprom',
    name: '"Газпромнефть"',
    mainImg: '/img/project/5/main.png',
    leftLink: 'czech',
    rightLink: 'shahristan',
    text: '1. АЗК № 109 формата Актив L. Алматинская область, Илийский район, поселок Боралдай, ул. Космонавтов, 84 "а". ​2. АЗС №116 формата Транзит L. Алматинская область, Карасайский район, ассоциация крестьянских хозяйств "Ленинский".',
    textTwo: 'Разработка проектно-сметной документации. Согласование с государственными органами.',
    date: 'Март 2012г. - Июль 2012г.',
    images: [
      {
        original: '/img/project/5/main.png',
        // thumbnail: '/img/project/4/main.png'
      }
    ]
  },
  {
    number: '6',
    id: 'shahristan',
    name: 'МЖК "Шахристан"',
    mainImg: '/img/project/6/main.jpg',
    leftLink: 'gazprom',
    rightLink: 'dostyk',
    text: 'Жилой комплекс "Шахристан" с объектами обслуживания и подземными автостоянками, расположенный в квадрате улиц Навои, Торайгырова, Рыскулбекова и реки большая Алматинка, г. Алматы. Пятна 2-1, 2-2, 2-3, 5-3, 4-1.',
    textTwo: 'Строительство под ключ в черновой отделке. Разработка котлована, монтаж временного энергообеспечения, поставка материалов и оборудования, работы по устройству монолитного железобетонного фундамента с гидроизоляцией, устройство монолитного железобетонного каркаса, проведение технических испытаний, сдача выполненных работ уполномоченным государственным органам.',
    date: 'Март 2012г. - Март 2015г.',
    images: [
      {
        original: '/img/project/6/main.jpg',
        // thumbnail: '/img/project/5/main.jpg'
      },
      {
        original: '/img/project/6/1.jpg',
        // thumbnail: '/img/project/5/1.png'
      },
      {
        original: '/img/project/6/2.jpg',
        // thumbnail: '/img/project/5/3.png'
      },
      {
        original: '/img/project/6/3.jpg',
        // thumbnail: '/img/project/5/1.png'
      },
      {
        original: '/img/project/6/4.jpg',
        // thumbnail: '/img/project/5/3.png'
      }
    ]
  },
  {
    number: '7',
    id: 'dostyk',
    name: 'МЖК "Достык Резиденс"',
    mainImg: '/img/project/7/main.jpg',
    leftLink: 'shahristan',
    rightLink: 'miras',
    text: 'Жилой комплекс "Достык Резиденс", расположенный по адресу: г. Алматы, Медеуский район, восточнее проспекта Достык, севернее улицы Митина.',
    textTwo: 'Выполнение комплекса общестроительных и отделочных работ.',
    date: 'Май 2015г. - Март 2016г.',
    images: [
      {
        original: '/img/project/7/main.jpg',
        // thumbnail: '/img/project/6/main.jpg'
      },
      {
        original: '/img/project/7/1.jpg',
        // thumbnail: '/img/project/6/1.jpg'
      },
      {
        original: '/img/project/7/2.jpg',
        // thumbnail: '/img/project/6/1.jpg'
      },
      {
        original: '/img/project/7/3.jpg',
        // thumbnail: '/img/project/6/1.jpg'
      }
    ]
  },
  {
    number: '8',
    id: 'miras',
    name: 'МЖК "Мирас Парк"',
    mainImg: '/img/project/8/main.jpg',
    leftLink: 'dostyk',
    rightLink: 'navoy',
    text: 'Многоквартирный жилой комплекс "Мирас Парк" с подземным автопаркингом, торговым и спортивно-- развлекательным центром, рассположенный по адресу: г. Алматы, Бостандыкский район, южнее проспекта Аль-Фараби, западнее проспекта Дулати.',
    textTwo: 'Выполнение комплекса общестроительных и отделочных работ.',
    date: 'Ноябрь 2014г. - Май 2016г.',
    images: [
      {
        original: '/img/project/8/main.jpg',
        // thumbnail: '/img/project/7/main.jpg'
      },
      {
        original: '/img/project/8/1.jpg',
        // thumbnail: '/img/project/7/1.jpg'
      },
      {
        original: '/img/project/8/2.jpg',
        // thumbnail: '/img/project/7/2.jpg'
      }
    ]
  },
  {
    number: '9',
    id: 'navoy',
    name: 'МЖК "Навои"',
    mainImg: '/img/project/9/main.jpg',
    leftLink: 'miras',
    rightLink: 'legend',
    text: 'Жилой комплекс "Навои"с объектами обслуживания и подземными паркингами, рассположенный по адресу: г. Алматы, Ауэзовский район, южнее улицы Жандосова, западнее улицы Навои.',
    textTwo: 'Комплекс общестроительных и специальных работ под ключ в черновой отделке.',
    date: 'Ноябрь 2015г. — Май 2017 г.',
    images: [
      {
        original: '/img/project/9/main.jpg',
        // thumbnail: '/img/project/8/main.jpg'
      },
      {
        original: '/img/project/9/1.jpg',
        // thumbnail: '/img/project/8/2.jpg'
      },
      {
        original: '/img/project/9/2.jpg',
        // thumbnail: '/img/project/8/2.jpg'
      },
      {
        original: '/img/project/9/3.jpg',
        // thumbnail: '/img/project/8/3.jpg'
      }
    ]
  },
  {
    number: '10',
    id: 'legend',
    name: 'МЖК "Легенда"',
    mainImg: '/img/project/10/main.jpg',
    leftLink: 'navoy',
    rightLink: 'zhana',
    text: 'Многоквартирный жилой комплекс «Легенда», расположенный в г.Алматы, Алмалинский район, проспект Абая, угол улицы Манаса, 1 очередь.',
    textTwo: 'Выполнение комплекса общестроительных и отделочных работ.',
    date: 'Август 2017г. — Сентябрь 2018 г.',
    images: [
      {
        original: '/img/project/10/main.jpg',
        // thumbnail: '/img/project/9/main.png'
      },
      {
        original: '/img/project/10/1.jpg',
        // thumbnail: '/img/project/9/1.jpg'
      },
      {
        original: '/img/project/10/2.jpg',
        // thumbnail: '/img/project/9/2.jpg'
      },
      {
        original: '/img/project/10/4.jpg',
        // thumbnail: '/img/project/9/4.jpg'
      },
      {
        original: '/img/project/10/5.jpg',
        // thumbnail: '/img/project/9/4.jpg'
      }
    ]
  },
  {
    number: '11',
    id: 'zhana',
    name: 'МЖК "Жана Жетiсу"',
    mainImg: '/img/project/11/main.jpg',
    leftLink: 'legend',
    rightLink: 'bank',
    text: 'Многоквартирный жилой комплекс «Жана Жетiсу», расположенный по адресу: г.Алматы, Ауэзовский район, мкр.Жетысу-1, д. 28А, Пятно 1, 4.',
    textTwo: 'Устройство монолитного железобетонного фундамента, устройство монолитного железобетонного каркаса, устройство фасада.',
    date: 'Июль 2017г. - Ноябрь 2018г.',
    images: [
      {
        original: '/img/project/11/main.jpg',
        // thumbnail: '/img/project/10/main.jpg'
      },
      {
        original: '/img/project/11/1.jpg',
        // thumbnail: '/img/project/10/1.jpg'
      },
      {
        original: '/img/project/11/2.jpg',
        // thumbnail: '/img/project/10/2.jpg'
      },
      {
        original: '/img/project/11/3.jpg',
        // thumbnail: '/img/project/10/3.jpg'
      },
      {
        original: '/img/project/11/4.jpg',
        // thumbnail: '/img/project/10/4.jpg'
      }
    ]
  },
  {
    number: '12',
    id: 'bank',
    name: 'Банк ВТБ (Казахстан)',
    mainImg: '/img/project/12/main.jpg',
    leftLink: 'zhana',
    rightLink: 'bus',
    text: 'Головной офис ДО АО Банк ВТБ (Казахстан) по адресу: Республика Казахстан, г. Алматы, ул. Тимирязева, дом 26/29.',
    textTwo: 'Проектные работы, комплекс общестроительных работ под ключ в чистовой отделке, ОВиК, Электромонтажные работы.',
    date: 'Октябрь 2018г. - Март 2019г.',
    images: [
      {
        original: '/img/project/12/main1.png',
        // thumbnail: '/img/project/11/main.jpg'
        thumbnailHeight: '435px'
      },
      {
        original: '/img/project/12/11.png',
        // thumbnail: '/img/project/11/1.jpg'
      },
      {
        original: '/img/project/12/22.png',
        // thumbnail: '/img/project/11/2.jpg'
      },
      {
        original: '/img/project/12/33.png',
        // thumbnail: '/img/project/11/3.jpg'
      }
    ]
  }
]

export default projectInfoArray