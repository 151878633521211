import React from "react";
import {Link} from 'react-router-dom'
import projectInfoArray from './array'
import FooterTwo from "./FooterTwo";

export default function ProjectsTwo() {

  const projectItems = projectInfoArray.map(i => {
    return (
      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
        <div className="projectBlock">
        <Link
            to={`/projects/${i.id}`}
            state={{ from: `${i.id}` }}
          >
            <div className="p-2">
              <img src={i.mainImg} alt="" width="200px" height="180px" className="projectImg" />
              <div className="color-dark-blue fw-proBold" style={{maxWidth: '200px'}}>{i.name}</div>
            </div>
          </Link>
        </div>
      </div>
    )
  })

  return (
    <div className="mainBg bodyReverse">
      <div className="subheader">
        <div className="container mx_930 fw-proBold">
          Проекты
        </div>
      </div>
      <div className="section container mx_930"  style={{position:'relative', padding: '50px 0px 80px'}}>
        {/* <Link to='/projects-second'
          className="buttonsProjectsInfoLeft buttonsProjectHideMobile">
          <img src="/img/arrow-left-circle.svg" width={'30px'} className="buttonsProjectInfoHover"/>
        </Link> */}
        <Link  to='/projects-second'
            className="buttonsProjectsInfoRight buttonsProjectHideMobile">
          {/* <img src="/img/arrow-right-circle.svg" width={'30px'}/> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" className="projectArrow" ><path d="M24 12l-9-9v7h-15v4h15v7z"/></svg>
          {/* <img src="/img/arrow-white.svg" width={'30px'} style={{borderRadius: '50%', backgroundColor: '#2c4e93', padding: '5px'}}/> */}
        </Link>
        <div className="row">
          {projectItems}
        </div>
      </div>
      <div className="text-center w-100 d-flex justify-content-center">
            <Link  to='/projects-second'
                className="d-block d-md-none text-center" style={{"maxWidth": '200px', "padding":"3px 25px","border":"1px solid #2c4e93","color":"#2c4e93","backgroundColor":"white","fontWeight":"600"}}>
              {/* <img src="/img/arrow-right-circle.svg" width={'30px'}/> */}
                Далее
            </Link>
          </div>
      <FooterTwo/>
    </div>
  )
}