import React, { Component } from "react";

import FooterTwo from "./FooterTwo";

export default function About() {

  return (
    <div className="mainBg bodyReverse d-flex flex-column justify-content-between">
      <div className="subheader">
        <div className="container mx_930 fw-proBold">
          О Компании
        </div>
      </div>
      <div className="container mx_930 fw-medium aboutContainer" style={{ fontSize: '17px'}}>
        <br/>
        <br className="d-block d-md-none" />
        <div className="fw-medium mx-auto" style={{maxWidth:'940px'}}>
          ТОО "Проектно-строительная компания "Дизайн Строй" основана в 2008 году в городе Алматы и осуществляет свою производственную деятельность на основании: Государственной лицензии на строительно-монтажные работы I категории; Государственной лицензии на Проектирование III категории.
          <br/>
          <br/>
          С момента основания построено более 215 тыс.м2 жилья и коммерческих помещений. Компания строила для таких Заказчиков как Казкоммерцбанк, через управляющую компанию Global Building Contract, Банк ВТБ (Казахстан), Базис-А, BI Stroy, КФ "Samruk-Kazyna Trust" и других Заказчиков,  имеет опыт работы с иностранными Заказчиками, может выступать как в роли Субподрядчика, так и Генерального Подрядчика.
          <br/>
          <br className="d-none d-md-block" />
          <br/>
          ТОО "ПСК "Дизайн Строй" выполняет весь комплекс строительно-монтажных работ различной степени сложности, начиная с разработки котлована и до сдачи объекта в эксплуатацию.
        </div>
      
      </div>

      <div>
        
      </div>

        <div className="text-center d-none d-md-block mx-auto" style={{margin: '100px auto 45px', maxWidth: '1200px'}}>
          <img src='/img/about.png' alt="projects reel" className="box-shadow-top" style={{width: '75%'}} />
        </div>

      <FooterTwo/>
    </div>
  )
}