import React from "react";

import FooterTwo from "./FooterTwo";

export default function About() {

  return (
    <div className="mainBg bodyHelper d-flex flex-column justify-content-between" style={{minHeight: '95vh'}}>
      <div className="subheader">
        <div className="container mx_930 fw-proBold" style={{fontSize: '14px'}}>
          ВОЗДУХООПОРНЫЕ СООРУЖЕНИЯ
        </div>
      </div>
      <section className="section pb-0 bg-white">
        <div className="container mx_930" style={{ fontSize: '17px'}}>
          <div className="fw-medium mx-auto" style={{maxWidth: '1000px'}}>
          
            Компания ТОО "ПСК "Дизайн Строй" предлагает в качестве альтернативы капитальному строительству для укрытия больших площадей и объёмов возведение Воздухоопорных Сооружений.
            <br /><br/>
            Воздухоопорная конструкция представляет собой сооружение, изготовленное из прочной, армированной ПВХ мембраны, закрепленной на фундамент.  Внутрь оболочки при помощи вентиляторов поступает воздух, создавая избыточное давление, которое является опорой для здания.
          
            Воздухоопорные сооружения чаще всего используются в таких сферах как:
            
          <ul className="fw-medium">
            <li className="fw-medium">организация спортивных площадок, например всесезонная эксплуатация теннисных кортов, крытых катков, бассейнов, футбольных, баскетбольных и других площадок;</li>
            <li className="fw-medium">для организации выставочных павильонов и торговых площадок, ярмарок, устройства цирковых и пр. выступлений;</li>
            <li className="fw-medium">для организации мобильных зданий медицинского и пр. назначений для ликвидации последствий стихийных бедствий;</li>
            <li className="fw-medium">в качестве временных производственных и складских помещений  и много другого.</li>
          </ul>
            Основными преимуществами конструкций являются:
          <ul className="fw-medium">
            <li className="fw-medium">
              простота и быстрота монтажа и демонтажа практически на любых видах почв;
            </li>
            <li className="fw-medium">
              возможность укрытия больших площадей и объёмов без возведения каких-либо опорных элементов;
            </li>
            <li className="fw-medium">
              невысокая стоимость строительства и эксплуатации по сравнению с капитальными зданиями;
            </li>
            <li className="fw-medium">
              высокая светопроницаемость, что позволяет оптимизировать расходы на освещение;
            </li>
            <li className="fw-medium">
              высокая сейсмическая безопасность;
            </li>
            <li className="fw-medium">
              мобильность и транспортабельность, что позволяет быструю перевозку и установку на новом месте.
            </li>
          </ul>
          <br/>
          <br/>
          <br/>
          </div>
        </div>
      </section>

      <div></div>

      <div className="d-none d-md-block" style={{margin: '0px auto 45px', }}>
        <img src='/img/cccc4.png' alt="" className="box-shadow-2" style={{maxWidth: '1000px'}}/>
      </div>
      <FooterTwo/>
    </div>

  )
}