import React from "react";

import {Link} from 'react-router-dom'
import FooterTwo from "./FooterTwo";

export default function Services() {

  return (
    <div className="mainBg bodyAdd" style={{minHeight: '95vh'}}>
      <div className="subheader">
        <div className="container mx_930 fw-proBold">
          Услуги
        </div>
      </div>
      <section className="section">
        <div className="container mx_930 fw-proBold">
          <div className="row fw-medium">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="box-shadow-1">
                <div className="img-box" style={{zIndex: 1}}>
                  <img src='/img/uslugi1.jpg' alt="" width="100%" />
                  <div className="img-text color-dark-blue fw-proBold">СТРОИТЕЛЬСТВО</div>
                </div>
                <p className="fw-medium mt-3 ms-2">
                  Строительство зданий и сооружений - это основная специализация компании <br className="d-none d-md-block" /> ТОО "ПСК "Дизайн Строй". <br className="d-none d-md-block" /> К особым компетенциям компании относится строительство Жилых комплексов разных классов комфортности и этажности; строительство административных зданий под ключ.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
              <div className="box-shadow-1">
                <div className="img-box">
                  <img src='/img/uslugi2.jpg' alt="" width="100%" style={{zIndex: 1}} />
                  <div className="img-text color-dark-blue fw-proBold">ПРОЕКТИРОВАНИЕ</div>
                </div>
                <p className="fw-medium mt-3 ms-2">
                  Проектирование, включая предпроектные работы, подготовку технико-экономического обоснования, разработку эскизного проекта и вплоть до прохождения экспертизы и согласования в соответствующих инстанциях.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
              <div className="box-shadow-1">
                <div className="img-box">
                  <img src='/img/uslugi3.png' alt="" width="100%" style={{zIndex: 1}} />
                  <div className="img-text color-dark-blue fw-proBold">КОНСАЛТИНГ</div>
                </div>
                <p className="fw-medium mt-3 ms-2">
                  Компания оказывает услуги строительного консалтинга на всех этапах строительных проектов, от анализа проектно-сметной документации и до ввода объекта в эксплуатацию.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mt-3 mt-sm-0">
              <div className="box-shadow-1">
                <div className="img-box">
                  <img src='/img/uslugi44.jpg' alt="" width="100%" style={{zIndex: 1}} />
                  <div className="img-text color-dark-blue fw-proBold" style={{maxWidth: '180px'}}>ВОЗДУХООПОРНЫЕ СООРУЖЕНИЯ</div>
                </div>
                <p className="fw-medium mt-3 ms-2">
                  Проектирование и строительство воздухоопорных сооружений под ключ.
                </p>
              </div>
              <div className="mt-5 d-flex justify-content-start justify-content-md-end ms-2 ms-md-0">
                <Link to='/services-link' className='podrobnee-link'>
                  Подробнее
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterTwo/>
    </div>
  )
}