import React, { useState } from "react";
import "./image-gallery.css";
import ImageGallery from 'react-image-gallery';
import { Link } from "react-router-dom";
import { useLocation, Redirect } from "react-router-dom"
import commonArray from "./commonArray";
import FooterTwo from "./FooterTwo";


function ProjectsInfo () {
  
  const location = useLocation()
  const { from } = location.state

  const sectionPart = commonArray.map(i => {
    if(i.id === from && i.id !== 'kaskad' && i.id !== 'sportcomplex'){
      return (
        <div className="container mx_930 d-flex align-items-center"  style={{position:'relative'}}>
          {/* <div className="buttonsProjectHideMobile"> */}
            <Link
                to={`/projects/${i.leftLink}`}
                state={{ from: `${i.leftLink}` }}

                className="buttonsProjectInfoLeft buttonsProjectHideMobile">
                  
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" className="projectArrow" style={{transform: 'rotate(180deg)'}} ><path d="M24 12l-9-9v7h-15v4h15v7z"/></svg>
                {/* <img src="/img/arrow.svg" width={'30px'} style={{borderRadius: '50%', backgroundColor: '', padding: '5px', border: '2px solid #2c4e93', transform: 'rotate(180deg)'}}/> */}
              </Link>
              <Link 
                to={`/projects/${i.rightLink}`}
                state={{ from: `${i.rightLink}` }}
                 className="buttonsProjectInfoRight buttonsProjectHideMobile">
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" className="projectArrow" ><path d="M24 12l-9-9v7h-15v4h15v7z"/></svg>
              </Link>
          {/* </div> */}
          <div className="pb-3 h-100">
              <div className="p-3">
                <div className="row">
                  <div className="col-12 col-md-6" 
                  // style={{ height: '435px', display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
                  style={{ backgroundColor: '#2c4e93', webkitBoxShadow: '-8px -8px 0px 2px rgba(0,0,0,0.27)', boxShadow: '-8px -8px 0px 2px rgba(0,0,0,0.37);', height: '435px', display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
                    {/* <div> */}
                    <ImageGallery items={i.images} showPlayButton={false} showBullets={true}/>
                    {/* </div> */}
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-md-0">
                    <div className="h-100 d-flex flex-column justify-content-between">
                      <h2 className="fw-medium w-100" style={{textAlign: 'right'}}>{i.name}</h2>
                      <div style={{fontSize: '16px'}}>
                        <div className="fw-proBold">Название проекта:</div> <div className="fw-medium">{i.text}</div> <br/>
                        <div className="fw-proBold">Описание проекта:</div> <div className="fw-medium">{i.textTwo}</div> <br/>
                        <div className="fw-proBold">Дата начала / завершения строительства:</div> <div className="fw-medium">{i.date}</div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className="d-flex w-100 justify-content-center">
                <Link
                    to={`/projects/${i.leftLink}`}
                    state={{ from: `${i.leftLink}` }}
                    className="me-2 d-block d-md-none text-center" style={{"maxWidth": '200px', "padding":"3px 25px","border":"1px solid #2c4e93","color":"#2c4e93","backgroundColor":"white","fontWeight":"600"}}>
                  {/* <img src="/img/arrow-right-circle.svg" width={'30px'}/> */}
                    Назад
                </Link>
                <Link
                    to={`/projects/${i.leftLink}`}
                    state={{ from: `${i.leftLink}` }}
                    className="ms-2 d-block d-md-none text-center" style={{"maxWidth": '200px', "padding":"3px 25px","border":"1px solid #2c4e93","color":"#2c4e93","backgroundColor":"white","fontWeight":"600"}}>
                  {/* <img src="/img/arrow-right-circle.svg" width={'30px'}/> */}
                    Далее
                </Link>
              </div>
          </div>
        </div>
      )
    }
    if(i.id === from && i.id === 'kaskad'){
      return(
        <div className="container mx_930 d-flex align-items-center"  style={{position:'relative'}}>
          {/* <div className="buttonsProjectHideMobile"> */}
            {/* <Link
                to={`/projects/${i.leftLink}`}
                state={{ from: `${i.leftLink}` }}

                className="buttonsProjectInfoLeft buttonsProjectHideMobile">
                  
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" className="projectArrow" style={{transform: 'rotate(180deg)'}} ><path d="M24 12l-9-9v7h-15v4h15v7z"/></svg>
              </Link> */}
              <Link 
                to={`/projects/${i.rightLink}`}
                state={{ from: `${i.rightLink}` }}
                 className="buttonsProjectInfoRight buttonsProjectHideMobile">
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" className="projectArrow" ><path d="M24 12l-9-9v7h-15v4h15v7z"/></svg>
              </Link>
          {/* </div> */}
          <div className="pb-3 h-100">
              <div className="p-3">
                <div className="row">
                  <div className="col-12 col-md-6" 
                  // style={{ height: '435px', display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
                  style={{ backgroundColor: '#2c4e93', webkitBoxShadow: '-8px -8px 0px 2px rgba(0,0,0,0.27)', boxShadow: '-8px -8px 0px 2px rgba(0,0,0,0.37);', height: '435px', display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
                    {/* <div> */}
                    <ImageGallery items={i.images} showPlayButton={false} showBullets={true}/>
                    {/* </div> */}
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-md-0">
                    <div className="h-100 d-flex flex-column justify-content-between">
                      <h2 className="fw-medium w-100" style={{textAlign: 'right'}}>{i.name}</h2>
                      <div style={{fontSize: '16px'}}>
                        <div className="fw-proBold">Название проекта:</div> <div className="fw-medium">{i.text}</div> <br/>
                        <div className="fw-proBold">Описание проекта:</div> <div className="fw-medium">{i.textTwo}</div> <br/>
                        <div className="fw-proBold">Дата начала / завершения строительства:</div> <div className="fw-medium">{i.date}</div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className="d-flex w-100 justify-content-center">
                {/* <Link
                    to={`/projects/${i.leftLink}`}
                    state={{ from: `${i.leftLink}` }}
                    className="me-2 d-block d-md-none text-center" style={{"maxWidth": '200px', "padding":"3px 25px","border":"1px solid #2c4e93","color":"#2c4e93","backgroundColor":"white","fontWeight":"600"}}>
                    Назад
                </Link> */}
                <Link
                    to={`/projects/${i.leftLink}`}
                    state={{ from: `${i.leftLink}` }}
                    className="ms-2 d-block d-md-none text-center" style={{"maxWidth": '200px', "padding":"3px 25px","border":"1px solid #2c4e93","color":"#2c4e93","backgroundColor":"white","fontWeight":"600"}}>
                  {/* <img src="/img/arrow-right-circle.svg" width={'30px'}/> */}
                    Далее
                </Link>
              </div>
          </div>
        </div>
      )
    }
    if(i.id === from && i.id === 'sportcomplex'){
      return(
        <div className="container mx_930 d-flex align-items-center"  style={{position:'relative'}}>
          {/* <div className="buttonsProjectHideMobile"> */}
            <Link
                to={`/projects/${i.leftLink}`}
                state={{ from: `${i.leftLink}` }}

                className="buttonsProjectInfoLeft buttonsProjectHideMobile">
                  
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" className="projectArrow" style={{transform: 'rotate(180deg)'}} ><path d="M24 12l-9-9v7h-15v4h15v7z"/></svg>
              </Link>
              {/* <Link 
                to={`/projects/${i.rightLink}`}
                state={{ from: `${i.rightLink}` }}
                 className="buttonsProjectInfoRight buttonsProjectHideMobile">
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" className="projectArrow" ><path d="M24 12l-9-9v7h-15v4h15v7z"/></svg>
              </Link> */}
          {/* </div> */}
          <div className="pb-3 h-100">
              <div className="p-3">
                <div className="row">
                  <div className="col-12 col-md-6" 
                  // style={{ height: '435px', display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
                  style={{ backgroundColor: '#2c4e93', webkitBoxShadow: '-8px -8px 0px 2px rgba(0,0,0,0.27)', boxShadow: '-8px -8px 0px 2px rgba(0,0,0,0.37);', height: '435px', display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
                    {/* <div> */}
                    <ImageGallery items={i.images} showPlayButton={false} showBullets={true}/>
                    {/* </div> */}
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-md-0">
                    <div className="h-100 d-flex flex-column justify-content-between">
                      <h2 className="fw-medium w-100" style={{textAlign: 'right'}}>{i.name}</h2>
                      <div style={{fontSize: '16px'}}>
                        <div className="fw-proBold">Название проекта:</div> <div className="fw-medium">{i.text}</div> <br/>
                        <div className="fw-proBold">Описание проекта:</div> <div className="fw-medium">{i.textTwo}</div> <br/>
                        <div className="fw-proBold">Дата начала / завершения строительства:</div> <div className="fw-medium">{i.date}</div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className="d-flex w-100 justify-content-center">
                <Link
                    to={`/projects/${i.leftLink}`}
                    state={{ from: `${i.leftLink}` }}
                    className="me-2 d-block d-md-none text-center" style={{"maxWidth": '200px', "padding":"3px 25px","border":"1px solid #2c4e93","color":"#2c4e93","backgroundColor":"white","fontWeight":"600"}}>
                    Назад
                </Link>
                {/* <Link
                    to={`/projects/${i.leftLink}`}
                    state={{ from: `${i.leftLink}` }}
                    className="ms-2 d-block d-md-none text-center" style={{"maxWidth": '200px', "padding":"3px 25px","border":"1px solid #2c4e93","color":"#2c4e93","backgroundColor":"white","fontWeight":"600"}}>
                  <img src="/img/arrow-right-circle.svg" width={'30px'}/>
                    Далее
                </Link> */}
              </div>
          </div>
        </div>
      )
    }
  })

  
    return (
      <div className="mainBg bodyReverse d-flex flex-column justify-content-between">
        <div className="subheader">
          <div className="container mx_930 fw-proBold">
            Проекты
          </div>
        </div>
        
          {sectionPart}

          <div style={{height: '40px'}}></div>

        <FooterTwo/>
      </div>
    )
}

export default ProjectsInfo