const projectInfoArrayTwo = [
  {
    number: '13',
    id: 'bus',
    name: 'Автобусный парк',
    mainImg: '/img/project/13/main.jpg',
    leftLink: 'bank',
    rightLink: 'asyltobe',
    text: 'Строительство Автобусного парка в Алатауском районе.',
    textTwo: 'Монолитные строительно-монтажные работы, бетонная подготовка, фундаменты, колонны, ригеля, стены, каналы и приямки, перекрытия, лестницы.',
    date: 'Март 2019г. - Октябрь 2019г.',
    images: [
      {
        original: '/img/project/13/main.jpg',
        // thumbnail: '/img/project/12/main.jpg'
      },
      {
        original: '/img/project/13/1.jpg',
        // thumbnail: '/img/project/12/2.jpg'
      },
      {
        original: '/img/project/13/2.jpg',
        // thumbnail: '/img/project/12/2.jpg'
      },
      {
        original: '/img/project/13/3.jpg',
        // thumbnail: '/img/project/12/3.jpg'
      }
    ]
  },
  {
    number: '14',
    id: 'asyltobe',
    name: 'МЖК "Асыл Төбе"',
    mainImg: '/img/project/14/main.jpg  ',
    leftLink: 'bus',
    rightLink: 'theatre',
    text: 'Многоквартирный жилой комплекс «Асыл Төбе», расположенный по адресу: г.Алматы, Егизбаева 7/А. Пятно 1.',
    textTwo: 'Устройство монолитного железобетонного каркаса, подпорных стен и приямков.',
    date: 'Март 2020г. - Октябрь 2020г.',
    images: [
      {
        original: '/img/project/14/main.jpg',
        // thumbnail: '/img/project/12/main.jpg'
      },
      {
        original: '/img/project/14/1.jpg',
        // thumbnail: '/img/project/12/2.jpg'
      },
      {
        original: '/img/project/14/2.jpg',
        // thumbnail: '/img/project/12/2.jpg'
      }
    ]
  },
  {
    number: '15',
    id: 'theatre',
    name: 'Театр балета',
    mainImg: '/img/project/15/main.jpg',
    leftLink: 'asyltobe',
    rightLink: 'sportcomplex',
    text: 'Строительство театра балета, г. Алматы, проспект Аль-Фараби, участок 30/5.',
    textTwo: 'Устройство монолитного железобетонного фундамента, устройство монолитного железобетонного каркаса, перекрытий по несъемной опалубке, установке анкеров для металлоконструкций. ',
    date: 'Июнь 2020 г - Июнь 2021',
    images: [
      {
        original: '/img/project/15/main.jpg',
        // thumbnail: '/img/project/12/main.jpg'
      },
      {
        original: '/img/project/15/1.jpg',
        // thumbnail: '/img/project/12/2.jpg'
      },
      {
        original: '/img/project/15/2.jpg',
        // thumbnail: '/img/project/12/2.jpg'
      },
      {
        original: '/img/project/15/3.jpg',
        // thumbnail: '/img/project/12/3.jpg'
      },
      {
        original: '/img/project/15/4.jpg',
        // thumbnail: '/img/project/12/3.jpg'
      }
    ]
  },
  {
    number: '16',
    id: 'sportcomplex',
    name: 'Многофункциональный спортивный комплекс',
    mainImg: '/img/project/16/main.jpg',
    leftLink: 'theatre',
    rightLink: 'kaskad',
    text: 'Строительство  многофункционального спортивного комплекса на базе воздухоопорных сооружений (12-ть комплексов в 12-ти регионах Республики Казахстан.',
    textTwo: 'Комплексное выполнение работ по проектированию и строительству многофункционального спортивного комплекса на базе воздухоопорных сооружений, «под ключ».',
    date: 'Декабрь 2020г. - текущий проект.',
    images: [
      {
        original: '/img/project/16/main.jpg',
        // thumbnail: '/img/project/12/main.jpg'
      },
      {
        original: '/img/project/16/1.jpg',
        // thumbnail: '/img/project/12/2.jpg'
      },
      {
        original: '/img/project/16/2.jpg',
        // thumbnail: '/img/project/12/2.jpg'
      },
      {
        original: '/img/project/16/3.jpg',
        // thumbnail: '/img/project/12/3.jpg'
      }
    ]
  }
]

export default projectInfoArrayTwo