import React from 'react'
import {Link} from 'react-router-dom'

import MobileMenu from './MobileMenu'

import './burgerMenu.css'

export default function Header({about}){

console.log(about)
  return(
    <div className='header d-flex align-items-center fw-proBold'>
      
      <div className='d-block d-lg-none'>
        <MobileMenu/>
      </div>

      <div className='container d-flex align-items-center justify-content-lg-between justify-content-center'>
    
        <div>
          <Link to='/'>
            <img src='/img/logo-full.png'  width={'100%'} style={{maxWidth: "190px"}}/>
          </Link>
        </div>

        <div className='d-none d-lg-block'>
          <Link to='/' className='pe-4 menu_link  fw-proBold'> Главная</Link>
          <Link to='/about' className='px-4 menu_link_border_left menu_link fw-proBold'> О Компании</Link>
          <Link to='/services' className='px-4 menu_link_border_left menu_link  fw-proBold'> Услуги</Link>
          <Link to='/projects' className='px-4 menu_link_border_left menu_link  fw-proBold'> Проекты</Link>
          <Link to='/contacts' className='px-4 menu_link_border_left menu_link  fw-proBold'> Контакты</Link>
        </div>

        <div className="fw-proBold d-none d-lg-block text-center" style={{fontSize: '24px', color: '#2c4e93'}}>
          г. Алматы
          <div style={{fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold'}}>info@designstroy.kz</div>
        </div>
      </div>   

    </div>
  )
} 