import React, {useState} from "react";
import FooterTwo from "./FooterTwo";
// import SheetDB from 'sheetdb-js'
import 'semantic-ui-css/semantic.min.css'
import { Button, Form } from 'semantic-ui-react'
import axios from "axios";

export default function Contacts() {

  const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();

		const objt = { name, email, message };

    alert('Вы успешно оставили заявку!')

		axios
			.post(
				'https://sheet.best/api/sheets/a6ec0428-271b-458c-9a47-0beaa61824b9',
				objt
			)
			.then((response) => {
				console.log(response);
			});
	};

    return (
      <div className="mainBg bodyAdd" style={{minHeight: '95vh'}}>
        <div className="subheader">
          <div className="container fw-proBold">
            Контакты
            </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6" style={{paddingRight: '10px', boxShadow: '7px 7px 5px 0px rgba(50, 50, 50, 0.301)'}}>
                <h3 className="color-dark-blue ms-2 fw-proBold">Свяжитесь с нами</h3>
                <form className="d-flex flex-column" style={{padding: '20px 40px'}}>
                  <input required onChange={(e) => setName(e.target.value)} placeholder="Имя" className="borderBInput fw-medium" style={{backgroundColor: 'transparent', padding: '20px 10px 5px', maxWidth: '250px'}} />
                  <input required onChange={(e) => setEmail(e.target.value)} placeholder="Эл.почта" className="borderBInput fw-medium" style={{backgroundColor: 'transparent', padding: '20px 10px 5px', maxWidth: '250px'}} />
                  <input required onChange={(e) => setMessage(e.target.value)} placeholder="Добавьте сообщение" className="borderBInput fw-medium" style={{backgroundColor: 'transparent', padding: '30px 10px'}} />
                  <div className="p-2 text-center">
                  <button 
                    type="submit"
                    onClick={handleSubmit}
                    className="mt-5" style={{backgroundColor: '#ffffff', fontWeight: 'bold', color: '#2c4e93', border: '1px solid #2c4e93', padding: '10px 30px'}}>Отправить</button>
                    </div>
                </form>

              </div>
              <div className="col-12 col-lg-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1098.7480774752446!2d76.87992531981071!3d43.219060520664854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x388368fb85052af5%3A0x884dc72a08d34cff!2sZhandosov%20Street%2098%2C%20Almaty!5e0!3m2!1sen!2skz!4v1622279486467!5m2!1sen!2skz" width="100%" height={400} style={{border: 0}} allowFullScreen loading="lazy" />
                
                <div className="mt-2">
                  <h5 className="fw-proBold color-dark-blue">ТОО "ПСК "Дизайн Строй""</h5>
                  <p className="color-dark-blue mb-0 ms-1 fw-medium">
                    г. Алматы, ул. Жандосова 98, БЦ Навои, <br /> офис 302 <br />
                    Время работы: 8:00 - 17:00 <br />
                    График работы: понедельник-пятница <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterTwo/>

      </div>
    )
}