
export default function Footer(){
  return(
    <div className="footer fw-proBold">
      <div className="subheader2 footer-mt">
        <div className="subheader2inner container d-flex justify-content-around d-flex" style={{maxWidth: '830px'}}>
          <div className="index_bottom-fs text-center fw-proBold" style={{width: '180px', padding: '5px 10px', backgroundColor: 'rgb(33, 43, 58)', borderRadius: '10px'}}>Строительство</div>
          <div className="index_bottom-fs text-center fw-proBold" style={{width: '180px', padding: '5px 10px', backgroundColor: 'rgb(33, 43, 58)', borderRadius: '10px'}}>Проектирование</div>
          <div className="index_bottom-fs text-center fw-proBold" style={{width: '180px', padding: '5px 10px', backgroundColor: 'rgb(33, 43, 58)', borderRadius: '10px'}}>Консалтинг</div>
        </div>
      </div>
      <div className="footer_inner text-center color-dark-blue fw-proBold">
        <span>©</span> 2008-2024 ТОО ПСК "ДизайнСтрой"
      </div>
    </div>
  

  )
}