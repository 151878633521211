import React from "react";
import Footer from './Footer'

export default function Home() {

  return (
    <div className="mainBg bodyAdd d-flex flex-column justify-content-between">
      <div className="subheader">
        <div className="container mx_930 fw-proBold">
          ПРОЕКТНО-СТРОИТЕЛЬНАЯ КОМПАНИЯ
        </div>
      </div>
      <div className="container mx_930" style={{padding: '30px 0px 30px'}}>
        <div className="d-flex flex-md-row flex-column justify-content-center justify-content-md-start align-items-center">
          <div className="mt-0 mt-md-5 mb-4 mb-md-0" style={{color: '#3a3938', maxWidth: '300px'}}>
            <div className="d-flex flex-row text-start align-items-center">
              <div>
                <img src="/img/building1.svg" width={'60px'}/>
              </div>
              <div className="fw-proBold" style={{fontSize: '14px', lineHeight: '22px', marginLeft: '30px'}}>
              ДАТА ОСНОВАНИЯ КОМПАНИИ: <br/>
              <span className="fw-black" style={{fontSize: '26px'}}>ноябрь 2008 г.</span>
              </div>
            </div>
            <br/>
            <br/>
            <div className="d-flex flex-row text-start align-items-end">
              <div>
                <img src="/img/building3.svg" width={'60px'}/>
              </div>
              <div className="fw-proBold" style={{fontSize: '14px', lineHeight: '22px', marginLeft: '30px'}}>
              ПОСТРОЕНО СВЫШЕ  <br/>
              <span className="fw-black" style={{fontSize: '26px'}}>215 тыс. м2</span>
               <br/>
              ЖИЛЬЯ И КОММЕРЧЕСКИХ <br/>
              ПОМЕЩЕНИЙ
              </div>
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-center mt-2 mt-md-0 msMd100  ">
            <img src='/img/main1.png' alt="house" style={{maxWidth: '300px', width: '100%'}} />
          </div>
        </div>
        <br className="d-block d-md-none" />
        <div className="d-flex align-items-center justify-content-center justify-content-md-end w-100 mtnMd200">
          <img src='/img/main2.png' alt="house" style={{maxWidth: '300px', width: '100%'}} />
        </div>
        <br className="d-block d-md-none" />
        <div className="d-flex align-items-center justify-content-center justify-content-md-end mt-2 mt-lg-3 mrlg">
          <img src='/img/main3.png' alt="house" style={{maxWidth: '300px', width: '100%'}} />
        </div>
      </div>

      <div></div>
      <br/>
      <br/>
      
      <Footer/>
    </div>
  )
}